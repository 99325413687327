.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.message-list {
  max-width: 500px;
  width: 100%;
  overflow: auto;
  height: 100%;
  position: relative;
  bottom: 0;
}

.message-container {
  padding: 0.5rem;
  border-bottom: 1px solid #eeeeee;
}

.chatUserImage, .date {
  font-size: 0.625rem;
  color: #888888;
}

.chatUserImage {
  min-width: 75px;
  float: left;
}

form {
  max-width: 500px;
  width: 100%;
}

input {
  width: 100%;
  padding: 0.5rem;
}

.chatBody {
  height: 600px;
}

.prevTurns {
  background-color: #dfdfdf;
}

.diceHeld {
  border-radius: 8px;
  border: 4px solid red;
}

.profileImageNavbar {
  border-radius: 30px;
}

.chatImage {
  width: 40px;
  margin-right: 10px;
  border-radius: 30px;
}

.lobbyListImage {
  width: 30px;
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 30px;
}

.systemMessage {
  background-color: #ccc;
  color: #666;
  text-align: center;
  padding: 2px;
  font-size: 10px;
  margin-top: 1px;
}

.diceClass {
  border-radius: 20px;
}

.turnDice {
  width: 80px;
}

.prevTurnDice {
  width: 40px;
}

.pastTurns {
  height: 400px;
}

.scrollable {
  overflow: auto;
}